import { SnackbarProvider } from 'notistack';
import React, { useEffect, ReactElement, ReactNode } from 'react';
import { AppProps } from 'next/app';
import {
  onLCP, onCLS, onTTFB, Metric,
} from 'web-vitals';
import type { NextPage } from 'next';
import { ThemeProvider } from '@mui/material/styles';
import ModalProvider from 'mui-modal-provider';
import CssBaseline from '@mui/material/CssBaseline';
import Router, { useRouter } from 'next/router';
import Honeybadger from '@honeybadger-io/js';
import { Manrope } from 'next/font/google';
import { parseCookies } from 'nookies';
import CookieConsent from 'react-cookie-consent';

import { sendPageToApp } from 'lib/utils/mobileAppUtils';
import 'lib/shims';
import { theme } from 'themes/muiTheme';
import { ApplicationStateProvider } from 'lib/contexts/ApplicationState';
import { trackPageView } from 'lib/gtag';
import { Layout } from 'components/Layout';

import './index.css';
import { RedirectToSignup } from 'components/RedirectToSignup';

const manrope = Manrope({
  subsets: ['latin'],
});

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  import('react-dom').then((ReactDOM) => {
    // eslint-disable-next-line import/no-extraneous-dependencies
    import('@axe-core/react').then((axe) => {
      axe.default(React, ReactDOM, 1000, {});
    });
  });
}

Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.GONDOLA_ENV,
  reportData: process.env.NODE_ENV === 'production',
  revision: process.env.GONDOLA_VERSION,
});

// This is handy for testing; remove it in production.
// if (typeof window !== 'undefined') {
//   (window as any).Honeybadger = Honeybadger;
// }

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
}

const MyApp = ({
  Component, pageProps,
}: AppPropsWithLayout) => {
  const router = useRouter();

  const getLayout = Component.getLayout || ((page: ReactElement) => <Layout>{page}</Layout>);
  const layoutWithComponent = getLayout(<Component {...pageProps} />);

  const cookies = parseCookies();

  const allowCookies = () => {
    if (!window.gtag) return;

    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  };

  const denyCookies = () => {
    if (!window.gtag) return;

    window.gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('unhandledrejection', (event) => {
        // These seem to be triggered by bots, extensions, etc. and we're overloading honeybadger
        // eslint-disable-next-line no-console
        console.error(event.reason);
      });
    }
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }

    const handleRouteStart = (url: string) => {
      sendPageToApp(url, 'start');
    };

    const handleRouteComplete = (url: string) => {
      trackPageView(url);
      sendPageToApp(url, 'complete');
    };

    // routeChangeComplete is better than routeChangeStart because the html title is correct
    Router.events.on('routeChangeStart', handleRouteStart); // add listener
    Router.events.on('routeChangeComplete', handleRouteComplete); // add listener

    const updateConsent = () => {
      if (cookies.eu !== '1') return;

      if (cookies.CookieConsent === 'true') {
        allowCookies();
      } else {
        denyCookies();
      }
    };

    updateConsent();

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart); // add listener
      Router.events.off('routeChangeComplete', handleRouteComplete); // remove listener
    };
  }, []);

  useEffect(() => {
    /**
     * If the showVitals query param is set, log the web vitals to the console.
     */
    const logMetrics = (metric: Metric) => {
      console.log(metric);
    };

    if (router.query?.showVitals) {
      onCLS(logMetrics);
      onLCP(logMetrics);
      onTTFB(logMetrics);
    }
  }, [router.query?.showVitals]);

  useEffect(() => {
    /** If featureFlag (ff) query param is set, add the value to local storage.
     *  if the query param is an empty string, delete from local storage.
     *
     *  We have a react hook (useFeatureFlag) that can get the current value from
     *  local storage. In addition, we send the feature flag to the backend via the
     *  API in a Feature-Flag http header.
     */
    if (router.query?.ff) {
      const flag = router.query?.ff as string;
      localStorage.setItem('featureFlag', flag);
    } else if (router.query?.ff === '') {
      localStorage.removeItem('featureFlag');
    }
  }, [router?.query?.ff]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApplicationStateProvider>
        <SnackbarProvider
          maxSnack={3}
          iconVariant={{
            success: '✅ ',
            error: '❗️ ',
            warning: '⚠️ ',
            info: 'ℹ️ ',
          }}
        >
          <ModalProvider>
            <style dangerouslySetInnerHTML={{
              __html: `
                html {
                  font-family: ${manrope.style.fontFamily};
                }`,
            }}
            />
            <RedirectToSignup>
              {layoutWithComponent}
            </RedirectToSignup>
            {cookies.eu === '1' && (
              <CookieConsent
                location="bottom"
                buttonText="Allow"
                declineButtonText="Deny"
                enableDeclineButton
                style={{ background: '#707070' }}
                buttonStyle={{ color: '#FFF', backgroundColor: '#F76F4A', borderRadius: '4px' }}
                declineButtonStyle={{ backgroundColor: '#707070' }}
                buttonWrapperClasses="m-auto"
                onAccept={allowCookies}
                onDecline={denyCookies}
              >
                <span className="font-bold">We use cookies </span>
                <span className="text-sm">
                  and other tracking technologies
                  to improve your browsing experience on our website,
                  and to understand where our visitors are coming from.
                </span>
              </CookieConsent>
            )}
          </ModalProvider>
        </SnackbarProvider>
      </ApplicationStateProvider>
    </ThemeProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default MyApp;
